<template>
    <div class="Media full-height pa-4 d-flex flex-column ">
        <div class="d-flex full-height-footerButtons">
            <div class="content-area pa-8 pt-5 flex-grow-1 bg-white ">
                <v-row class="d-flex align-center mb-5 filter">
                    <v-col cols="5" class="pl-0 d-flex align-center">
                        <p>{{ $t('general.status') }}:</p>
                        <v-select :items="filterStatus" outlined hide-details="auto" v-model="filterStatusSelected" attach class="ml-6"/>
                    </v-col>
                </v-row>

                <v-data-table
                    disable-pagination
                    hide-default-footer
                    :headers="productsTableHeaders"
                    :items="filteredList"
                >
                    <template v-slot:body="{ items }">
                        <tbody>
                        <tr v-for="(item) in items" :key="item.id" @click="editRow(item)">
                            <td>{{ item.name }}</td>
                            <td>{{ findStatus(item.status) }}</td>
                            <td :class="{edit: item.editable && checkUserRights('mediaEdit'), show: !item.editable || !checkUserRights('mediaEdit')}"></td>
                        </tr>
                        </tbody>
                    </template>
                </v-data-table>
            </div>
        </div>
        <v-bottom-navigation grow dark background-color="disiBluegreyLight" class="pt-4" height="76">
            <footer-button buttonFunction="back" @footerButtonClick="goToSystem"></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button buttonFunction="new" @footerButtonClick="newMedia" :class="{'disabled': !checkUserRights('mediaEdit')}"></footer-button>
        </v-bottom-navigation>
    </div>
</template>

<script>
import FooterButton from '@/components/FooterButton.vue'
import {mapState} from "vuex";
import mixins from "@/mixins/mixins";

export default {
    name: 'Media',
    components: {
        FooterButton,
    },
    props: {
    },
    data () {
        return {
            filterStatusSelected: 'show all',
        }
    },
    computed: {
        ...mapState([
            'medialist',
        ]),
        productsTableHeaders(){
            return [
                { text: this.$t('general.name'), align: 'start', value: 'name' },
                { text: this.$t('general.status'), value: 'status' },
                { text:"", sortable: false,}
            ]
        },
        filteredList(){
            let filteredlist = this.$store.state.medialist;
            if (this.filterStatusSelected == this.$t('filter.showActive')){
                filteredlist = filteredlist.filter(function(media){
                    return media.status == true;
                })
            } else if (this.filterStatusSelected == this.$t('filter.showInactive')){
                filteredlist = filteredlist.filter(function(media){
                    return media.status == false;
                })
            }
            return filteredlist;
        },
        filterStatus(){
            return [
                this.$t('filter.showAll'),
                this.$t('filter.showActive'),
                this.$t('filter.showInactive'),
            ]
        },
    },
    methods: {
        goToSystem(){
            this.$router.push('system');
        },
        findStatus(statusValue){
            let status = this.$t('general.active')
            if (statusValue === false){
                status = this.$t('general.inactive')
            }
            return status;
        },
        editRow(media){
            this.$store.commit('ChangeEditMedia', media);
        },
        newMedia(){
            let media = {
                id: null,
                name: "",
                status: true,
                editable: true,
            }
            this.$store.commit('ChangeEditMedia', media);
        },
    },
    mixins: [mixins],
    created() {
        //get Medialist
        this.$store.dispatch('getAxiosSetter', ['/disi/media/list', 'medialist']);
    },
}
</script>
<style scoped lang="scss">
</style>